// Import React and jQuery libraries
import React, { Component } from 'react';
import UserList from './components/UserList'; 
import $ from 'jquery'; 
import 'jquery-ui-dist/jquery-ui.css'; 
import 'jquery-ui-dist/jquery-ui.js'; 

// main app component
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            searchQuery: '', 
        };
    }

    // runs after the component is first rendered
    componentDidMount() {
        this.fetchUsers(); 
        this.setupAutocomplete();
    }

    // sets up the autocomplte feature using jquery ui
    setupAutocomplete = () => {
        $('#search-input').autocomplete({
            source: (request, response) => {
                $.ajax({
                    url: `/search_users.php`,
                    data: { query: request.term }, 
                    dataType: 'json', 
                    success: (data) => {
                        // on success show the user names as autocomplete suggestions
                        response(data.map(user => user.name)); 
                    },
                    error: (error) => {
                        console.error('Error fetching autocomplete data:', error);
                    },
                });
            },
            minLength: 2, // min 2 characters
        });
    };

    // Fetch users from the server
    fetchUsers = () => {
        const { searchQuery } = this.state; 
        fetch(`/search_users.php?query=${searchQuery}`) 
            .then(response => response.json())
            .then(data => {
                // Update the state with the fetched users
                this.setState({ users: data });
            })
            .catch(error => console.error('Error fetching users:', error));
    };

    // update the search query in the state when the input field changes
    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };

    // Handle the search button click to fetch users
    handleSearchClick = () => {
        this.fetchUsers(); 
    };

    render() {
        // Extract users and searchQuery from the state
        const { users, searchQuery } = this.state;

        return (
            <div className="container">
                <h1>Classmates</h1>
                <div className="search-container" style={{ marginBottom: '20px' }}>
                    <input
                        id="search-input" 
                        type="text"
                        value={searchQuery} 
                        onChange={this.handleSearchChange} 
                        placeholder="Search for a classmate" 
                        style={{ padding: '8px', fontSize: '16px', width: '300px', marginRight: '10px' }}
                    />
                    <button
                        onClick={this.handleSearchClick}
                        style={{ padding: '8px 16px', fontSize: '16px', cursor: 'pointer' }}
                    >
                        Search
                    </button>
                </div>
                <div className="results">
                    <UserList users={users} />
                </div>
            </div>
        );
    }
}

export default App;
