// Import React to create components
import React from 'react';

// A functional component to display individual user details
function UserItem(props) {
    // This component accepts 'props' to get the user data (name and email)
    return (
        // Return a list item (<li>) with the user's name and email displayed
        <li>{props.name} - {props.email}</li>
    );
}

// Export the component so it can be used in other parts of the app
export default UserItem;
