// Import React to create components
import React from 'react';
// import the UserItem component to display individual users
import UserItem from './UserItem';

// Component that receives 'props' and displays a list of users
function UserList(props) {
    return (
        <ul>
            {props.users.map(user => (
                // for each user create a UserItem component with the user's name and email
                <UserItem 
                    key={user.id}
                    name={user.name}
                    email={user.email}
                />
            ))}
        </ul>
    );
}

// export the UserLit component so it can be used in other parts of the app
export default UserList;
